
.floating {
  animation: floating 3s ease infinite;
  will-change: transform; }
  .floating:hover {
    animation-play-state: paused; }

.floating-lg {
  animation: floating-lg 3s ease infinite; }

.floating-sm {
  animation: floating-sm 3s ease infinite; }

@keyframes floating-lg {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(15px); }
  100% {
    transform: translateY(0px); } }

@keyframes floating {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

@keyframes floating-sm {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0px); } }

.floatfix:before, .floatfix:after {
  content: '';
  display: table; }

.floatfix:after {
  clear: both; }

.overflow-visible {
  overflow: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

[class*="alpha"] {
  transition: all 0.2s ease; }

[class*="faded"] {
  position: relative; }
  [class*="faded"]:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 30%; }

.faded-top:after {
  top: 0;
  background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 ); }

.faded-bottom:after {
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 ); }

[class*='hover-'] {
  transition: all 0.2s ease; }
  @media (prefers-reduced-motion: reduce) {
    [class*='hover-'] {
      transition: none; } }






.icon {
  min-width: 3rem;
  min-height: 3rem;
  line-height: 3rem; }
  .icon i, .icon svg {
    font-size: 2.75rem; }
  .icon img {
    width: 3rem;
    height: 3rem; }

.icon + .icon-text {
  padding-left: 1rem;
  width: calc(100% - 3rem - 1); }

.icon-xl {
  min-width: 5rem;
  min-height: 5rem;
  line-height: 5rem; }
  .icon-xl i, .icon-xl svg {
    font-size: 4.75rem; }
  .icon-xl img {
    width: 5rem;
    height: 5rem; }

.icon-xl + .icon-text {
  width: calc(100% - 5rem - 1); }

.icon-lg {
  min-width: 4rem;
  min-height: 4rem;
  line-height: 4rem; }
  .icon-lg i, .icon-lg svg {
    font-size: 3.75rem; }
  .icon-lg img {
    width: 4rem;
    height: 4rem; }

.icon-lg + .icon-text {
  width: calc(100% - 4rem - 1); }

.icon-sm {
  min-width: 2rem;
  min-height: 2rem;
  line-height: 2rem; }
  .icon-sm i, .icon-sm svg {
    font-size: 1.75rem; }
  .icon-sm img {
    width: 2rem;
    height: 2rem; }

.icon-sm + .icon-text {
  width: calc(100% - 2rem - 1); }

.icon-xs {
  min-width: 1rem;
  min-height: 1rem;
  line-height: 1rem; }
  .icon-xs i, .icon-xs svg {
    font-size: 0.75rem; }
  .icon-xs img {
    width: 1rem;
    height: 1rem; }

.icon-xs + .icon-text {
  width: calc(100% - 1rem - 1); }

.icon-shape {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.25rem; }
  .icon-shape i, .icon-shape svg {
    font-size: 1.25rem; }
  .icon-shape.icon-xl i, .icon-shape.icon-xl svg {
    font-size: 2.25rem; }
  .icon-shape.icon-lg i, .icon-shape.icon-lg svg {
    font-size: 1.75rem; }
  .icon-shape.icon-sm i, .icon-shape.icon-sm svg {
    font-size: 1rem; }
  .icon-shape.icon-xs i, .icon-shape.icon-xs svg {
    font-size: .75rem; }

.icon-primary {
  color: #6e00ff;
  background-color: #e2ccff; }

.icon-outline-primary {
  color: #4f00b8;
  border: 3px solid rgba(139, 51, 255, 0.5); }

.icon-secondary {
  color: #EFF2F7;
  background-color: #fcfcfd; }

.icon-outline-secondary {
  color: #bdcbe2;
  border: 3px solid rgba(255, 255, 255, 0.5); }

.icon-success {
  color: #36B37E;
  background-color: #d7f0e5; }

.icon-outline-success {
  color: #218058;
  border: 3px solid rgba(81, 203, 151, 0.5); }

.icon-info {
  color: #00B8D9;
  background-color: #ccf1f7; }

.icon-outline-info {
  color: #007b92;
  border: 3px solid rgba(13, 218, 255, 0.5); }

.icon-warning {
  color: #FFAB00;
  background-color: #ffeecc; }

.icon-outline-warning {
  color: #b87b00;
  border: 3px solid rgba(255, 188, 51, 0.5); }

.icon-danger {
  color: #FF5630;
  background-color: #ffddd6; }

.icon-outline-danger {
  color: #e82b00;
  border: 3px solid rgba(255, 128, 99, 0.5); }

.icon-light {
  color: #EFF2F7;
  background-color: #fcfcfd; }

.icon-outline-light {
  color: #bdcbe2;
  border: 3px solid rgba(255, 255, 255, 0.5); }

.icon-dark {
  color: #273444;
  background-color: #d4d6da; }

.icon-outline-dark {
  color: #0c1118;
  border: 3px solid rgba(58, 77, 100, 0.5); }

.icon-neutral {
  color: #FFF;
  background-color: white; }

.icon-outline-neutral {
  color: #dddada;
  border: 3px solid rgba(255, 255, 255, 0.5); }

.icon-white {
  color: #FFF;
  background-color: white; }

.icon-outline-white {
  color: #dddada;
  border: 3px solid rgba(255, 255, 255, 0.5); }

.icon-actions > a {
  display: inline-block;
  margin-right: .75rem;
  color: #C0CCDA;
  font-size: .875rem; }
  .icon-actions > a:last-of-type {
    margin-right: 0; }
  .icon-actions > a span {
    margin-left: .1875rem;
    font-weight: 600;
    color: #8492A6; }
  .icon-actions > a:hover span {
    color: #68788f; }

.icon-actions > a,
.icon-actions > a:hover,
.icon-actions > a.active {
  color: #3C4858; }

.icon-actions > .favorite:hover,
.icon-actions > .favorite.active {
  color: #ffcc00; }

.icon-actions > .love:hover,
.icon-actions > .love.active {
  color: #FF5630; }

.icon-actions > .like:hover,
.icon-actions > .like.active {
  color: #2684FF; }

.icon-actions-lg a {
  font-size: 1.25rem;
  margin-right: .875rem; }

.has-floating-items {
  padding-bottom: 18rem !important; }
  .has-floating-items svg {
    height: auto; }

@media (min-width: 768px) {
  .has-floating-items {
    padding-bottom: 18rem !important; } }

.floating-items {
  position: relative;
  left: 50%;
  margin-left: -570px;
  z-index: 10; }

.icon-floating {
  position: absolute;
  text-align: center;
  box-shadow: 0 30px 40px 0 rgba(31, 45, 61, 0.1);
  border-radius: 50%; }
  .icon-floating svg {
    width: 50px; }
  .icon-floating span {
    height: 100%; }
  .icon-floating img {
    max-width: 100%; }
  .icon-floating span,
  .icon-floating img {
    display: inline-block;
    vertical-align: middle; }

.icon-floating:nth-child(1) {
  top: 140px;
  left: -60px;
  width: 120px;
  height: 120px; }

.icon-floating:nth-child(2) {
  top: 200px;
  left: 180px;
  width: 160px;
  height: 160px;
  animation-delay: .5s; }

.icon-floating:nth-child(3) {
  top: 120px;
  left: 480px;
  width: 80px;
  height: 80px;
  animation-delay: 1s; }

.icon-floating:nth-child(4) {
  top: 210px;
  left: 680px;
  width: 140px;
  height: 140px;
  animation-delay: 1.5s; }

.icon-floating:nth-child(5) {
  top: 100px;
  left: 920px;
  width: 100px;
  height: 100px;
  animation-delay: 2s; }

.icon-floating:nth-child(6) {
  top: 200px;
  left: 1120px;
  width: 80px;
  height: 80px;
  animation-delay: 2.5s; }

.icon-sm svg {
  width: 36px; }

.icon-lg svg {
  width: 76px; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .icon-floating:nth-child(1), .icon-floating:nth-child(6) {
    display: none; } }

@media (max-width: 991.98px) {
  .icon-floating:nth-child(1), .icon-floating:nth-child(2), .icon-floating:nth-child(3) {
    display: none; }
  .icon-floating:nth-child(4), .icon-floating:nth-child(5), .icon-floating:nth-child(6) {
    width: 100px;
    height: 100px;
    top: 80px; }
  .icon-floating:nth-child(4) {
    left: 300px; }
  .icon-floating:nth-child(5) {
    left: 500px; }
  .icon-floating:nth-child(6) {
    left: 700px; }
  .icon-floating svg {
    width: 40px; } }

@media (max-width: 767.98px) {
  .icon-floating:nth-child(4), .icon-floating:nth-child(5), .icon-floating:nth-child(6) {
    width: 80px;
    height: 80px;
    top: 80px; }
  .icon-floating:nth-child(4) {
    left: 400px; }
  .icon-floating:nth-child(5) {
    left: 530px; }
  .icon-floating:nth-child(6) {
    left: 660px; } }

.floating-color {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 50%;
  animation: floating 3s ease infinite; }
  .floating-color span {
    display: inline-block;
    border-radius: 20px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.5); }

.floating-color:nth-child(1) {
  top: 140px;
  left: -60px;
  width: 120px;
  height: 120px; }

.floating-color:nth-child(2) {
  top: 200px;
  left: 180px;
  width: 160px;
  height: 160px;
  animation-delay: .5s; }

.floating-color:nth-child(3) {
  top: 120px;
  left: 480px;
  width: 80px;
  height: 80px;
  animation-delay: 1s; }

.floating-color:nth-child(4) {
  top: 210px;
  left: 680px;
  width: 140px;
  height: 140px;
  animation-delay: 1.5s; }

.floating-color:nth-child(5) {
  top: 100px;
  left: 920px;
  width: 100px;
  height: 100px;
  animation-delay: 2s; }

.floating-color:nth-child(6) {
  top: 200px;
  left: 1120px;
  width: 80px;
  height: 80px;
  animation-delay: 2.5s; }

section {
  background-color: #FFF; }

.slice {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem; }

.slice-xl {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.slice-lg {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.slice-sm {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.slice:not(.border-top):not(.border-bottom):not(.delimiter-bottom):not([class*="bg-"]):not(.section-rotate) + .slice:not(.border-top):not(.border-bottom):not(.delimiter-top):not([class*="bg-"]) {
  padding-top: 0; }

.slice-video {
  min-height: 600px; }

.section-rotate {
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
  background: transparent;
  position: relative;
  z-index: 0; }
  .section-rotate .section-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2; }

@media (min-width: 992px) {
  .section-rotate {
    min-height: 880px;
    padding-top: 8rem;
    padding-bottom: 8rem; }
    .section-rotate .section-inner {
      background: inherit;
      overflow: hidden; }
      .section-rotate .section-inner + .container {
        position: relative;
        z-index: 0; }
    .section-rotate .section-inner-bg {
      position: absolute;
      width: 100%;
      height: 100%; } }

@media (min-width: 1200px) {
  .section-rotate {
    padding-top: 8rem;
    padding-bottom: 12rem; }
    .section-rotate .section-inner {
      top: -40%;
      left: -1rem;
      width: 130%;
      height: 124%;
      transform: rotate(-7deg);
      border-bottom-left-radius: 3rem; }
      .section-rotate .section-inner + .container {
        padding-left: 2rem; }
    .section-rotate .container {
      position: relative; } }

@media only screen and (min-width: 2000px) {
  .section-rotate .section-inner {
    top: -40%;
    left: -1rem;
    width: 120%;
    height: 118%; }
  .section-rotate .container {
    position: relative; } }

.section-half-rounded {
  padding-top: 7rem;
  padding-bottom: 7rem;
  overflow: hidden;
  background: transparent;
  position: relative;
  z-index: 0; }
  .section-half-rounded .section-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    z-index: -2;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px; }
  @media (max-width: 991.98px) {
    .section-half-rounded .section-inner {
      width: 100%; } }

.section-process {
  background: transparent; }

.section-process-step {
  position: relative;
  padding: 4.5rem 0; }
  .section-process-step:not(:last-child):before {
    content: "";
    display: block;
    width: 360px;
    height: 100px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23EFF2F7' stroke-width='3' transform='translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)' d='M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") no-repeat center;
    background-size: 360px 100px;
    transform: rotate(40deg);
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -80px; }
    @media (max-width: 991.98px) {
      .section-process-step:not(:last-child):before {
        transform: rotate(90deg) scale(0.7);
        bottom: 20px; } }
  .section-process-step:nth-child(even):before {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23EFF2F7' stroke-width='3' d='M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") no-repeat center;
    background-size: 360px 100px;
    transform: rotate(-40deg); }
    @media (max-width: 991.98px) {
      .section-process-step:nth-child(even):before {
        transform: rotate(-90deg) scale(0.7);
        bottom: 20px; } }

.section-floating-icons {
  --icon-size: 5rem;
  --icon-sm-size: 3.75rem;
  --gutter: 7rem; }
  .section-floating-icons .icons-container {
    position: relative;
    max-width: 100%;
    height: 360px;
    margin: 0 auto; }
    .section-floating-icons .icons-container span {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #FFF;
      z-index: 1;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
      transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25); }
      @media (prefers-reduced-motion: reduce) {
        .section-floating-icons .icons-container span {
          transition: none; } }
      .section-floating-icons .icons-container span.icon {
        width: 5rem;
        height: 5rem; }
        .section-floating-icons .icons-container span.icon i {
          font-size: 2.3em; }
      .section-floating-icons .icons-container span.icon-sm {
        width: 3.75rem;
        height: 3.75rem; }
        .section-floating-icons .icons-container span.icon-sm i {
          font-size: 1.5em; }
      .section-floating-icons .icons-container span:nth-child(1) {
        font-size: 42px;
        color: #FFAB00;
        z-index: 2; }
      .section-floating-icons .icons-container span img {
        max-width: 100%; }
    .section-floating-icons .icons-container span {
      opacity: 1; }
      .section-floating-icons .icons-container span:nth-child(1) {
        left: 50%;
        top: 50%;
        font-size: 42px;
        color: #00B8D9; }
      .section-floating-icons .icons-container span:nth-child(2) {
        left: calc(50% + (7rem * 1.7));
        top: 50%; }
      .section-floating-icons .icons-container span:nth-child(3) {
        left: calc(50% + 7rem);
        top: calc(50% + 7rem); }
      .section-floating-icons .icons-container span:nth-child(4) {
        left: calc(50% + 7rem);
        top: calc(50% - 7rem); }
      .section-floating-icons .icons-container span:nth-child(5) {
        left: calc(50% + (7rem * 4));
        top: 50%; }
      .section-floating-icons .icons-container span:nth-child(6) {
        left: calc(50% + (7rem * 2.7));
        top: calc(50% + (7rem * 1.5)); }
      .section-floating-icons .icons-container span:nth-child(7) {
        left: calc(50% + (7rem * 2.7));
        top: calc(50% - (7rem * 1.5)); }
      .section-floating-icons .icons-container span:nth-child(8) {
        left: calc(50% - (7rem * 1.7));
        top: 50%; }
      .section-floating-icons .icons-container span:nth-child(9) {
        left: calc(50% - 7rem);
        top: calc(50% + 7rem); }
      .section-floating-icons .icons-container span:nth-child(10) {
        left: calc(50% - 7rem);
        top: calc(50% - 7rem); }
      .section-floating-icons .icons-container span:nth-child(11) {
        left: calc(50% - (7rem * 4));
        top: 50%; }
      .section-floating-icons .icons-container span:nth-child(12) {
        left: calc(50% - (7rem * 2.7));
        top: calc(50% + (7rem * 1.5)); }
      .section-floating-icons .icons-container span:nth-child(13) {
        left: calc(50% - (7rem * 2.7));
        top: calc(50% - (7rem * 1.5)); }


.tongue {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: rotate(180deg) translateX(50%);
  width: 138px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #8492A6;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E"); }
  .tongue i {
    animation: floating-sm 2s ease infinite; }
  .tongue:hover i {
    animation-play-state: paused; }

.tongue-primary {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%236e00ff' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-primary:hover {
    color: #FFF; }

.tongue-secondary {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23EFF2F7' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-secondary:hover {
    color: #273444; }

.tongue-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%2336B37E' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-success:hover {
    color: #FFF; }

.tongue-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%2300B8D9' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-info:hover {
    color: #FFF; }

.tongue-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFAB00' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-warning:hover {
    color: #FFF; }

.tongue-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FF5630' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-danger:hover {
    color: #FFF; }

.tongue-light {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23EFF2F7' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-light:hover {
    color: #273444; }

.tongue-dark {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23273444' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-dark:hover {
    color: #FFF; }

.tongue-neutral {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-neutral:hover {
    color: #273444; }

.tongue-white {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-white:hover {
    color: #273444; }

.tongue-section-primary {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-section-primary:hover {
    color: #273444; }

.tongue-section-secondary {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FAFBFE' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-section-secondary:hover {
    color: #273444; }

.tongue-section-light {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23E0E6ED' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #273444; }
  .tongue-section-light:hover {
    color: #273444; }

.tongue-section-dark {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%231a232d' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  color: #FFF; }
  .tongue-section-dark:hover {
    color: #FFF; }

.tongue-top {
  top: -1px; }

.tongue-bottom {
  top: auto;
  bottom: -1px;
  transform: translateX(-50%); }
  .lead {
    font-size: 1.125rem;
    font-weight: 300; }