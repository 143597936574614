.cardheader{
    
   width: 100%; 
   height:80px;
   border-radius:10px
}
.bg-hijau {
    background:#149346 !important
}

.image-radius{
    width: 100%;height:150px;border-radius: 20px;
}

.img-post{
    position: absolute; right: 0;
}
.mapouter{
    position:relative;
    text-align:right;
    height:339px;
    width:100%;
}
.gmap_canvas {
    overflow:hidden;
    border-radius: 20px;
    background:none!important;
    height:339px;width:100%;
}